import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout siteTitle="">
    <SEO title="Home" />
    <div
      className="jumbotron d-flex justify-content-center flex-column"
      style={{ backgroundColor: "black", height: "300px", borderRadius: "0" }}
    >
      <div>
        <h1 className="display-4 text-primary">BIER IMPORT</h1>
        <div className="lead text-secondary">Tysk tradisjon, Norsk fyrings</div>
      </div>
    </div>
    <div className="container">
      {/* <p>Velkommen til oss!</p> */}
    </div>
  </Layout>
)

export default IndexPage
